import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "caret", "header"]

  connect() {
    // Initialize the state (minimized by default for existing sessions)
    this.expanded = this.element.querySelector('h3').textContent.includes('New Session')
    this.updateView()
  }

  toggle() {
    this.expanded = !this.expanded
    this.updateView()
  }

  updateView() {
    this.contentTarget.classList.toggle("hidden", !this.expanded)
    this.caretTarget.classList.toggle("rotate-180", !this.expanded)
    this.headerTarget.classList.toggle("rounded-md", !this.expanded)
    this.headerTarget.classList.toggle("rounded-t-md", this.expanded)
  }
}