import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async requested(event) {
    event.preventDefault();
    const requestForm = document.getElementById('request_form');
    const sentModal = document.getElementById('sent_modal')
    if (requestForm) {
      requestForm.classList.add('hidden');
      sentModal.classList.remove('hidden')
    }
  }
}
