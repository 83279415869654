import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
    
    static targets = ["container"];
    connect() {
    this.scrollToLastMsg()
    this.startObserving();
  }

  startObserving() {
    const config = { childList: true, subtree: true };
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              this.handleNewElement(node);
            }
          });
        }
      });
    });

    this.observer.observe(this.containerTarget, config);
  }

  handleNewElement(newElement) {
    const lastChild = this.containerTarget.lastElementChild;

    if (lastChild) {
      lastChild.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  scrollToLastMsg() {
    const element = document.getElementById('scroll');
        element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  clear() {
    this.element.reset()
    
  }

  async search(e) {
      const currentUrl = window.location.pathname
      const query = e.currentTarget.value;
      const response = await get(`${currentUrl}/search.turbo_stream?query=${query}`)
  }
}