// app/javascript/controllers/camp_image_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  remove(event) {
    event.preventDefault()
    event.stopPropagation()
    
    if (confirm('Are you sure you want to remove this image?')) {
      fetch(this.urlValue, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      })
      .then(response => {
        if (response.ok) {
          this.element.closest('.inline-block').remove()
        } else {
          console.error("Failed to remove image:", response.status, response.statusText)
          alert('Failed to remove image')
        }
      })
      .catch(error => {
        console.error("Error:", error)
      })
    }
  }
}