import { Controller } from '@hotwired/stimulus'
import { Tabs } from "tailwindcss-stimulus-components"

export default class extends Tabs {
  static targets = ["button", 'connectionsList', 'searchInput']

  connect() { 
    window.addEventListener("click", this.hideOnOutsideClick.bind(this));
     super.connect()
  }

  disconnect() {
    window.removeEventListener("click", this.hideOnOutsideClick.bind(this));
  }

  redirect(event) {
    const url = this.element.dataset.urlValue;
    if (event.target.tagName !== "A" && event.target.tagName !== "BUTTON" && event.target !== document.getElementById('options')) {
      window.location.href = url;
    }
  }
  
  submit(event) {
    event.preventDefault();
    this.buttonTarget.innerHTML = "Requested";
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add("bg-gray-400"); 
    event.currentTarget.form.requestSubmit();
  }

  async search(e) {
    if (e.currentTarget.value === "") {
      this.connectionsListTarget.classList.add("hidden");
      return;
    }
    const response = await fetch(`/connections/search?query=${e.currentTarget.value}`);
    document.getElementById('search_connection_list').innerHTML = await response.text();
    this.connectionsListTarget.classList.remove('hidden')
  }

   hideOnOutsideClick(event) {
    if (!this.searchInputTarget.contains(event.target) && !this.connectionsListTarget.contains(event.target)) {
      this.connectionsListTarget.classList.add("hidden");
    }
  }

  async toggleChanged(event) {
    const url = event.target.checked ? `/connections?camp_connections=true` : ``;
    event.target.disabled = true;
    try {
      const response = await fetch(url);
      document.getElementById('camp_connections').innerHTML = await response.text();
    } catch (error) {
      alert(error);
    } finally {
      event.target.disabled = false;
    }
  }

  showTab() {
    this.updateFollowingVisibility()
    super.showTab()
  }

  updateFollowingVisibility() {
    const followingDiv = document.getElementById('following');
    if (followingDiv) {
      if (this.indexValue === 0) {
        followingDiv.style.display = 'block';
      } else {
        followingDiv.style.display = 'none';
      }
    }
  }
}