// app/javascript/controllers/session_dropdown_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "subtotal"]

  connect() {
    this.updateSubtotal()
  }

  updateSubtotal() {
    let total = 0
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        const price = parseFloat(checkbox.dataset.price || 0)
        total += price
      }
    })

    if (this.hasSubtotalTarget) {
      this.subtotalTarget.textContent = `$${total.toFixed(2)}`
    }
  }

  toggleCheckbox(event) {
    this.updateSubtotal()
  }
}