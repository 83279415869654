// app/javascript/controllers/places_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["place"]

    connect() {
        if(typeof(google) != "undefined") {
            this.initMap()
        }
    }

    initMap() {
        this.autoComplete = new google.maps.places.Autocomplete(this.placeTarget)
    }
}