// app/javascript/controllers/filter_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "fromSlider", "toSlider", "minPrice", "maxPrice", "categoryPill", "categoryInput",
    "agePill", "ageInput"
  ];

  connect() {
    this.MIN = parseInt(this.fromSliderTarget.getAttribute('min'));
    this.MAX = parseInt(this.fromSliderTarget.getAttribute('max'));
    this.fillSlider();
    this.setToggleAccessible();

    // Set initial field values based on URL params (if present)
    const urlParams = new URLSearchParams(window.location.search);
    const minPriceParam = urlParams.get("min_price") || 0;
    const maxPriceParam = urlParams.get("max_price") || 1000;

    if (minPriceParam) {
      this.fromSliderTarget.value = minPriceParam;
      this.minPriceTarget.value = minPriceParam;
    }
    if (maxPriceParam) {
      this.toSliderTarget.value = maxPriceParam;
      this.maxPriceTarget.value = maxPriceParam;
    }
  }

  toggleCategory(event) {  
    const pill = event.currentTarget;
    const value = pill.dataset.value;
  
    const input = this.categoryInputTarget;
  
    let selectedCategories = input.value ? input.value.split(',') : [];
  
    if (selectedCategories.includes(value)) {
      selectedCategories = selectedCategories.filter(cat => cat !== value);
      pill.classList.remove('bg-green-900/75', 'text-white');
      pill.classList.add('bg-green-900/10', 'text-green-900');
    } else {
      selectedCategories.push(value);
      pill.classList.remove('bg-green-900/10', 'text-green-900');
      pill.classList.add('bg-green-900/75', 'text-white');
    }
  
    input.value = selectedCategories.join(',');
  }

  toggleAge(event) {
    const pill = event.currentTarget;
    const value = pill.dataset.value; // e.g., "3-5"
    const input = this.ageInputTarget;
    
    let selectedAges = input.value ? input.value.split(',') : [];
    
    if (selectedAges.includes(value)) {
      selectedAges = selectedAges.filter(age => age !== value);
      pill.classList.remove('bg-green-900/75', 'text-white');
      pill.classList.add('bg-green-900/10', 'text-green-900');
    } else {
      selectedAges.push(value);
      pill.classList.remove('bg-green-900/10', 'text-green-900');
      pill.classList.add('bg-green-900/75', 'text-white');
    }
    
    input.value = selectedAges.join(',');
  }  
  

  // Control the fromSlider
  controlFromSlider() {
    const [from, to] = this.getParsed();
    this.fillSlider();
    if (from > to) {
      this.fromSliderTarget.value = to;
    }
    this.setFieldValue(this.fromSliderTarget, this.minPriceTarget);
  }

  // Control the toSlider
  controlToSlider() {
    const [from, to] = this.getParsed();
    this.fillSlider();
    this.setToggleAccessible();
    if (from <= to) {
      this.toSliderTarget.value = to;
    } else {
      this.toSliderTarget.value = from;
    }
    this.setFieldValue(this.toSliderTarget, this.maxPriceTarget);
  }

  // Parse the slider values
  getParsed() {
    const from = parseInt(this.fromSliderTarget.value, 10);
    const to = parseInt(this.toSliderTarget.value, 10);
    return [from, to];
  }

  // Fill the slider background
  fillSlider() {
    const rangeDistance = this.toSliderTarget.max - this.toSliderTarget.min;
    const fromPosition = this.fromSliderTarget.value - this.toSliderTarget.min;
    const toPosition = this.toSliderTarget.value - this.toSliderTarget.min;
    this.toSliderTarget.style.background = `linear-gradient(
      to right,
      #CBD5E1 0%,
      #CBD5E1 ${(fromPosition) / (rangeDistance) * 100}%,
      #0EA5E9 ${((fromPosition) / (rangeDistance)) * 100}%,
      #0EA5E9 ${(toPosition) / (rangeDistance) * 100}%,
      #CBD5E1 ${(toPosition) / (rangeDistance) * 100}%, 
      #CBD5E1 100%)`;
  }

  // Set the field values based on slider position
  setFieldValue(slider, field) {
    const value = slider.value;
    field.value = value;
  }

  // Set accessible toggle for the toSlider
  setToggleAccessible() {
    if (Number(this.toSliderTarget.value) <= 0) {
      this.toSliderTarget.style.zIndex = 2;
    } else {
      this.toSliderTarget.style.zIndex = 0;
    }
  }
}
