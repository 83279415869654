import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["campInterest", "interestInput", "userInterests"]
  static values = {
    campInterests: Array,
  }

  connect() {
    if(this.hasCampInterestTarget) {
      const usersInterests = this.campInterestTarget.value;
      this.campInterestsValue = usersInterests.split(",");
    }
  }

  uploadImage(e) {
    e.target.form.requestSubmit();
  }

  removeInterest(e) {
    const { interest } = e.currentTarget.dataset;
    document.getElementById(`interest_${interest}`).remove();
    let interests = this.campInterestsValue;
    const updatedInt = interests.filter((interst) => interst != interest);
    this.campInterestsValue = updatedInt;
    document.getElementById("user_camping_interests").value = this.campInterestsValue.join(",")
  }

  showInterestInput(e) {
    this.interestInputTarget.parentNode.classList.toggle("hidden");
  }

  submitForm(e) {
    e.target.textContent = 'Saving...';
    e.target.classList.add('opacity-70');
    document.getElementById("edit_users_form").requestSubmit();
  }

  addInterest(e) {
    e.preventDefault();
    let interests = this.campInterestsValue;
    interests.push(this.interestInputTarget.value);
    this.campInterestsValue = interests;
    document.getElementById("user_camping_interests").value = this.campInterestsValue.join(",")

    const Newinterest = `
      <div class="bg-gray-10 px-3 py-2 rounded-full flex items-center w-fit" id="interest_${this.interestInputTarget.value}">
        ${this.interestInputTarget.value}
        <div class="ml-1 cursor-pointer">
          <svg data-action="click->users#removeInterest" data-interest="${this.interestInputTarget.value}" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.87588 7.93321L11.2325 5.57588C11.2962 5.51438 11.347 5.44082 11.3819 5.35948C11.4169 5.27815 11.4353 5.19067 11.436 5.10215C11.4368 5.01363 11.4199 4.92584 11.3864 4.84391C11.3529 4.76198 11.3034 4.68754 11.2408 4.62495C11.1782 4.56235 11.1038 4.51285 11.0218 4.47933C10.9399 4.44581 10.8521 4.42894 10.7636 4.42971C10.6751 4.43048 10.5876 4.44887 10.5063 4.48381C10.4249 4.51875 10.3514 4.56954 10.2899 4.63321L7.93255 6.98988L5.57588 4.63321C5.51438 4.56954 5.44082 4.51875 5.35948 4.48381C5.27815 4.44887 5.19067 4.43048 5.10215 4.42971C5.01363 4.42894 4.92584 4.44581 4.84391 4.47933C4.76198 4.51285 4.68754 4.56235 4.62495 4.62495C4.56235 4.68754 4.51285 4.76198 4.47933 4.84391C4.44581 4.92584 4.42894 5.01363 4.42971 5.10215C4.43048 5.19067 4.44887 5.27815 4.48381 5.35948C4.51875 5.44082 4.56954 5.51438 4.63321 5.57588L6.98988 7.93255L4.63321 10.2899C4.56954 10.3514 4.51875 10.4249 4.48381 10.5063C4.44887 10.5876 4.43048 10.6751 4.42971 10.7636C4.42894 10.8521 4.44581 10.9399 4.47933 11.0218C4.51285 11.1038 4.56235 11.1782 4.62495 11.2408C4.68754 11.3034 4.76198 11.3529 4.84391 11.3864C4.92584 11.4199 5.01363 11.4368 5.10215 11.436C5.19067 11.4353 5.27815 11.4169 5.35948 11.3819C5.44082 11.347 5.51438 11.2962 5.57588 11.2325L7.93255 8.87588L10.2899 11.2325C10.3514 11.2962 10.4249 11.347 10.5063 11.3819C10.5876 11.4169 10.6751 11.4353 10.7636 11.436C10.8521 11.4368 10.9399 11.4199 11.0218 11.3864C11.1038 11.3529 11.1782 11.3034 11.2408 11.2408C11.3034 11.1782 11.3529 11.1038 11.3864 11.0218C11.4199 10.9399 11.4368 10.8521 11.436 10.7636C11.4353 10.6751 11.4169 10.5876 11.3819 10.5063C11.347 10.4249 11.2962 10.3514 11.2325 10.2899L8.87588 7.93255V7.93321Z" fill="#C1C7CD"/>
          </svg>
        </div>
      </div>
    `
    this.userInterestsTarget.insertAdjacentHTML('beforeend', Newinterest);
    this.interestInputTarget.value = '';
  }
}
