import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput","submit","uploadedFile"]

  files = []
  openFileInput(event) {
    event.preventDefault()
    this.fileInputTarget.click()
  }

  handleFileUpload(event) {
    const file = event.target.files[0]
    const camperId = event.target.dataset.uploadCampersIdValue
    if (file && camperId) {
      this.files.push({ file, camperId })
      this.fileUploaded(camperId, file)
      this.enableSubmit()
    }
  }

  fileUploaded(camperId, file) {
    const uploadedFileTarget = this.uploadedFileTargets.find(
        target => target.dataset.uploadCampersIdValue === camperId
    );
      if (uploadedFileTarget) {
        uploadedFileTarget.textContent = `${file.name}`;
      }
  }

  submitForm(event) {
    event.preventDefault();
    const { formId, cartId } = event.currentTarget.dataset;
    const formData = new FormData();
    this.files.forEach((fileObj, index) => {
      formData.append(`camper_medical_form[${index}][file]`, fileObj.file);
      formData.append(`camper_medical_form[${index}][camper_id]`, fileObj.camperId);
    });
    formData.append('id', cartId)
    formData.append('form_id', formId)

    fetch('/checkout/upload_files', {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
      }).then(response => {
      if (response.ok) {
        window.location.reload();
      } else {
        console.error('Failed to upload files');
      }
    }).catch(error => {
      console.error('File upload error', error);
    });
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
    this.submitTarget.classList.remove("bg-gray-300");
    this.submitTarget.classList.add("bg-blue-600");  
  }
}
