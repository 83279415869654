import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static targets = ["target", "template", "customDateInput", "registrationRadio", "preRegistrationDiv", "allowPreRegCheckbox", "preRegistrationRadio", "preRegistrationStartDate"]
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
    totalObj: {
      type: Number,
      default: 1
    }
  }

  connect() {
    this.toggleCustomDateInput()
    this.togglePreRegistrationDiv()
    this.updateCampUrl()
  }

  updateCampUrl() {
    if (this.hasCampSelectTarget && this.hasCampUrlTarget) {
      this.campSelectTarget.addEventListener('change', (event) => {
        const selectedCampId = event.target.value
        if (selectedCampId) {
          this.campUrlTarget.textContent = `https://www.campsaroundtown.com/camps/${selectedCampId}`
        } else {
          this.campUrlTarget.textContent = 'https://www.campsaroundtown.com/camps/'
        }
      })
    }
  }

  toggleCustomDateInput() {
    this.registrationRadioTargets.forEach(radio => {
      radio.addEventListener('change', (event) => {
        if (event.target.value === 'true') {
          this.customDateInputTarget.style.display = 'block'
        } else {
          this.customDateInputTarget.style.display = 'none'
        }
      })
    })
  }

  togglePreRegistrationDiv() {
    if(this.hasAllowPreRegCheckboxTarget) {
      this.allowPreRegCheckboxTarget.addEventListener('change', (event) => {
        if (event.target.checked) {
          this.preRegistrationDivTarget.style.display = 'block'
        } else {
          this.preRegistrationDivTarget.style.display = 'none'
        }
      })
    }
  }

  togglepreRegistrationStartDate(event) {
    if (this.hasPreRegistrationStartDateTarget) {
      this.preRegistrationStartDateTarget.style.display = event.target.value === 'true' ? 'block' : 'none'
    }
  }

  async toggleStatus(e) {
    const { sessionId, campId } = e.currentTarget.dataset;
    const response = await patch(`/camps_admin/camps/${campId}/sessions/${sessionId}/update_status`)
  }

  add(e){
    e.preventDefault()
    const date = new Date().getTime().toString();

    let temp = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, date)
    const content = temp.replace(/session_name_0/g, `session_name_${date}`);
    this.targetTarget.insertAdjacentHTML("beforebegin", 
      `<div class="nested-form-wrapper" data-new-record="true">
        ${content}
      </div>`)

    this.totalObjValue = this.totalObjValue + 1;
    const event = new CustomEvent("sessions:add", { bubbles: true })
    this.element.dispatchEvent(event);
    const countNodes = document.getElementsByClassName("sessions_detail_count");
    // countNodes[countNodes.length -1].textContent = this.totalObjValue;
  }

  remove(event) {
    event.preventDefault()
    const wrapper = event.target.closest('.nested-form-wrapper')
    if (wrapper) {
      wrapper.remove()
    }
  }

  nonRecurring(e) {
    const day = e.currentTarget.dataset.day;
    let classes = e.currentTarget.classList;
    if(classes.contains('border-2')) {
      classes.remove('border-2');
    } else {
      classes.add('border-2');
    }

    const selectedDays = e.target.parentNode.querySelectorAll('.border-2');
    const selectElement = document.getElementById(e.target.parentNode.querySelector("select").id);

    if (selectElement) {
      // Clear all selections in the <select> element
      Array.from(selectElement.options).forEach(option => {
        option.selected = false;
      });

      // Loop through selectedDays and select matching options
      selectedDays.forEach(dayElement => {
        const dayValue = dayElement.dataset.day;
        Array.from(selectElement.options).forEach(option => {
          if (option.value === dayValue) {
            option.selected = true;
          }
        });
      }); 
    }
  }

  closeEditMode(e) {
    e.target.closest('div').classList.toggle('hidden');
    const hiddenInput = e.target.closest("div").querySelector('input[type="hidden"]');
    const sessionNum = hiddenInput.id.split("_").at(-2);
    document.getElementById(`session_name_${sessionNum}`).classList.toggle('hidden');
  }

  editName(e) {
    e.target.closest('div').querySelector('.session-name').classList.toggle('hidden');
    e.target.closest('div').querySelector('.h6').classList.toggle('hidden');
  }

  updateName(e) {
    const name = e.currentTarget.value;
    const hiddenInput = e.target.closest("div").querySelector('input[type="hidden"]');
    hiddenInput.value = name;
    const sessionNum = hiddenInput.id.split("_").at(-2);
    document.getElementById(`session_name_${sessionNum}`).textContent = name;
  }
}