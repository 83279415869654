import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static targets = ["dialog"]

  async toggleCheckIn(e) {
    const target = e.currentTarget;
    const { camperId } = target.dataset;

    const body = JSON.stringify({
      camper_session_id: camperId
    });

    const response = await fetch(`/camps_admin/rosters/toggle_check_in`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ camper_session_id: camperId })
    });    

    if (response.ok) {
      const data = await response.json();

      if (data.checked_in) {
        target.classList.remove("bg-green-900");
        target.classList.add("bg-green-700");
        target.textContent = "Checked In";
      } else {
        target.classList.remove("bg-green-700");
        target.classList.add("bg-green-900");
        target.textContent = "Check In";
      }
    } else {
      console.error("Failed to toggle check-in status:", response);
    }
  }
}
