import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static targets = ["dialog","fileInput","menu","campNameInput", "campNameDisplay"]
  timeoutId = null;
  
  connect() {
    console.log("Camps controller connected");
    // auto camps form submission after every 10s
    // const campsForm = document.getElementById("camps_form");
    // if(campsForm) {
    //   this.timeoutId = setInterval(this.submitCampsForm, 10000);
    // }
  }

  disconnect() {
    // clearInterval(this.timeoutId) 
  }

  updateCampName() {
    const newName = this.campNameInputTarget.value.trim();
    this.campNameDisplayTarget.textContent = newName || "New camp";
  }

  submitCampsForm(e) {
    const btn = document.getElementById("submit_camps");
    const savedMessage = document.getElementById("changes-saved-message");
    btn.classList.add("opacity-70");
    const tab = document.querySelector('[data-tabs-index-value]')?.getAttribute('data-tabs-index-value') || 0;
    let hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'active_tab';
    hiddenField.value = tab;
    document.getElementById("camps_form").appendChild(hiddenField);
    
    document.getElementById("camps_form").requestSubmit();

    // Show the "Changes saved" message
    savedMessage.classList.remove("hidden");

    // Reset the button and hide the message after a delay
    setTimeout(() => {
      btn.textContent = "Save";
      btn.classList.remove("opacity-70");
      savedMessage.classList.add('fade-out');
    }, 3000); // Message will disappear after 3 seconds
  }

  focusOut(e) {
    document.getElementById("camps-h").classList.toggle("hidden");
    document.getElementById("camps-name").classList.toggle("hidden");
    document.querySelector(".cedt-icon").classList.remove("hidden");
  }

  async toggleStatus(e) {
    const { campId } = e.currentTarget.dataset;
    const response = await patch(`/camps_admin/camps/${campId}/update_status`)
  }

  loadSessions(event) {
    event.preventDefault();
    const campId = event.currentTarget.dataset.campId;
    
    // Find the sessions target
    const sessionsTarget = this.element.nextElementSibling;
    // Find the chevron target within the clicked element
    const chevronTarget = event.currentTarget.querySelector('[data-camps-target="chevron"]');

    console.log(`Loading sessions for camp ID: ${campId}`);
    console.log('Sessions container:', sessionsTarget);
    console.log('Chevron icon:', chevronTarget);

    if (!sessionsTarget || !chevronTarget) {
      console.error("Sessions target or chevron target not found");
      return;
    }

    // Toggle visibility of the sessions container
    if (sessionsTarget.style.display === 'none' || sessionsTarget.style.display === '') {
      sessionsTarget.style.display = 'table-row';
      chevronTarget.style.transform = 'rotate(180deg)';
    } else {
      sessionsTarget.style.display = 'none';
      chevronTarget.style.transform = 'rotate(0deg)';
    }

    // Check if sessions are already loaded
    const turboFrame = sessionsTarget.querySelector('turbo-frame');
    if (turboFrame && turboFrame.innerHTML.trim() === '') {
      console.log('Fetching sessions data...');
      // If sessions are not loaded, fetch them
      fetch(`/camps_admin/camps/${campId}/sessions.turbo_stream`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(data => {
          console.log('Sessions data received:', data.substring(0, 100) + '...'); // Log first 100 characters
          turboFrame.innerHTML = data;
        })
        .catch(error => {
          console.error('Error:', error);
          turboFrame.innerHTML = '<p>Error loading sessions. Please try again.</p>';
        });
    } else {
      console.log('Sessions already loaded');
    }
  }

  toggleDialog(e) {
    this.dialogTarget.classList.toggle("hidden")
  }

  dropdown() {
    const dropdown = document.getElementById('dropdown');
    dropdown.classList.toggle('hidden');

  }

  select(event) {
    const selectedOption = event.currentTarget.textContent.trim();
    document.getElementById("selected-option").textContent = selectedOption;
    this.menuTarget.classList.add("hidden");
  }
}