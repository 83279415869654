/* eslint no-console:0 */

import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("local-time").start()

import "dropzone"

import "./channels"
import "./controllers"
import "./src/**/*"

// Import Chartkick and Chart.js
import Chartkick from "chartkick"
import Chart from "chart.js/auto"

// Initialize Chartkick with Chart.js
Chartkick.use(Chart)

// Make Chartkick available globally
window.Chartkick = Chartkick

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

window.initMap = function(...args) {
    const event = new CustomEvent('google-maps-callback', {
        detail: { args: args },
        bubbles: true,
        cancelable: false
    });
    window.dispatchEvent(event);
}