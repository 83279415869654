// app/javascript/controllers/modal_toggle_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  toggle(event) {
    event.preventDefault()
    this.modalTarget.classList.toggle("hidden")
  }
}