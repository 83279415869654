import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["passwordForm", "loginWithPasswordButton"]

  connect() {
    // Hide the password form initially
    this.passwordFormTarget.classList.add("hidden")
  }

  showPasswordForm(event) {
    // Show the password form and hide the 'Login with password' button
    this.passwordFormTarget.classList.remove("hidden")
    this.loginWithPasswordButtonTarget.classList.add("hidden")
  }

  hidePasswordForm(event) {
    // Hide the password form and show the 'Login with password' button
    this.passwordFormTarget.classList.add("hidden")
    this.loginWithPasswordButtonTarget.classList.remove("hidden")
  }
}
