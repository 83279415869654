// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = {
    roles: Array
  }

  reconnect(event) {
    if (consumer.connection.isActive()) {
      consumer.connection.reopen()
    }
  }

  connect() {
    if(document.getElementById('account_invitation_role_u')) {
      this.inviteeRole();
    }
  }

  uploadImage(e) {
    e.target.form.requestSubmit();
  }

  submitForm(e) {
    e.target.textContent = 'Saving...';
    e.target.classList.add('opacity-70');
    document.getElementById("edit_accounts_form").requestSubmit();
  }

  inviteeRole(e) {
    for(let i=0; i< this.rolesValue.length; i++) {
      let checkbox = document.getElementById(this.rolesValue[i]);
      if(checkbox.checked) {
        // we can invite user with one role. so make sure only one role is checked
        // if role is already selected then un-check all checked roles. and finally check the current role.
        document.getElementById(this.rolesValue[i]).click();
      }
    }

    //on page load select the first role.
    let role = e?.target?.value || document.getElementById('account_invitation_role_u').value;
    document.getElementById(role).click();
  }
}
