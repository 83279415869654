import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input", "preview"];
  static values = {
    message: { type: String, default: "Drag and drop new images here or click to upload" }
  }

  connect() {
    this.dropzone = new Dropzone(this.previewTarget, {
      url: this.data.get("url"),
      paramName: this.data.get("paramName"),
      maxFilesize: this.data.get("maxFilesize"),
      acceptedFiles: this.data.get("acceptedFiles"),
      addRemoveLinks: true,
      previewsContainer: this.previewTarget,
      thumbnailWidth: 120,
      thumbnailHeight: 120,
      autoProcessQueue: false,
      dictDefaultMessage: this.messageValue,
    });

    this.dropzone.on("addedfile", file => this.handleAddedFile(file));
  }

  handleAddedFile(file) {
    const upload = new DirectUpload(file, this.data.get("url"), this);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.error(error);
      } else {
        // Add an appropriately-named hidden input to the form with a value of blob.signed_id
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.data.get("paramName");
        this.element.closest('form').appendChild(hiddenField);

        // Process the dropzone file (remove it from the queue)
        this.dropzone.emit("complete", file);
        this.dropzone.emit("success", file);
      }
    });
  }

  triggerFileInput() {
    this.inputTarget.click();
  }

  disconnect() {
    this.dropzone.destroy();
  }
}